* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    width: 98.8vw;
    font-size: 16px;
    --black: #343434;
    --bg: #f8e367;
    --pink: #fe956f;
    font-family: Poppins,sans-serif;
    background-color: var(--bg);
    overflow-x: hidden;
}