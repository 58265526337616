.singlereview-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: visible;
    position: relative;
}

.image-container{
    width: 75px;
    min-height: 75px;
    position: relative;
    transform: translatey(-50%);
    border-radius: 50%;
}

img{
    width: 100%;
    height: 100%;
}

.comments{
    width: 90%;
    border-bottom:2px solid var(--black);
    font-size: 1rem;
    word-spacing: 3px;
    letter-spacing: 1px;
    text-align: justify;
    padding: 5px;
    color: black
}

.name{
    min-width: 50%;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 2%;
    color: var(--black);
}


@media screen and (max-width: 1200px){
    .comments{
        font-size: 0.9rem;
    }

    .name{
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 950px) {
    .comments{
        font-size: 0.8rem;
    }

    .name{
        font-size: 1.2rem;
    }
}

