.ProductsMain-container
{
    width: 70%;
    height: 70vh;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 3), 1fr));
    overflow-y: scroll;
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: hsla(0,0%,100%,.2);
    border-radius: 10px;
  }

::-webkit-scrollbar-thumb {
    background: skyblue;
    border-radius: 10px;
}


@media screen and (max-width:1050px) {
    .ProductsMain-container{
        grid-template-columns: repeat(auto-fill, minmax(calc(100% / 2), 1fr));
    }
}

@media screen and (max-width:700px) {
    .ProductsMain-container{
        grid-template-columns: repeat(auto-fill, minmax(calc(100% / 1), 1fr));
    }
}

@media screen and (max-width:550px) {
    .ProductsMain-container{
        grid-template-columns: repeat(auto-fill, minmax(calc(100% / 1), 1fr));
    }

    .ProductsMain-container
    {
        width: 75%;
    }
}

@media screen and (max-width:400px) {
    .ProductsMain-container
    {
        width: 80%;
    }
}

@media screen and (max-width:400px) {
    .ProductsMain-container
    {
        width: 85%;
    }
}