.HomeMain-container{
    width: 100%;
    height: 100vh;
}

@media screen and (max-width:650px){
    .HomeMain-container{
        width: 100%;
        height: initial;
    }
}