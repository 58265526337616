.Productsheading-container{
    text-align: center;
    height: 170px;
    margin-top: 60px;
    font-size: 3rem;
    font-weight: 500;
}


#pheadimg{
    max-width: 200px;
    max-height: 100px;
}

@media screen and (max-width:500px) {
    .Productsheading-container{
        font-size: 2.0rem;
        width: 90%;
        margin: 100px auto 0px;
        height: 130px;
    }

    #pheadimg{
        max-width: 150px;
        max-height: 50px;
        vertical-align:bottom;
        margin-right: 200px;
    }
}