.ProfileMain{
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: row;
}

.profilechild1{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.profilechild1h1{
    height: 30%;
    font-size: 1.5rem;
    color: var(--black);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}

.profilechild1bottom{
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#profilechild1bottomh1{
    color: var(--black);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    font-size: 2.5rem;
}

#profilechild1bottomp{
    color: var(--black);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 60%;
    font-size: 1.2rem;
}

/*        profilechild2             */

.profilechild2{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}

.profilechild2bg{
    width: 75%;
    height: 95%;
    border-radius: 50%;
    background-color: #0eaff1;
}

/*        profilechild3             */

.profilechild3{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
}

#profilechild3top{
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#profilechild3bottom{
    width: 100%;
    height: 30%;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 5%;
}

.profilechild3h1{
    font-size: 2.5rem;
    color: var(--black);
    text-align: center;
}

.profilechild3p{
    color: var(--black);
    font-size: 1.2rem;
    text-align: center;
}

/*                   profilechild3icons                */

#profilechild3icons{
    height: 14%;
    width: 256px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: -80%;
    bottom: 20%;
}

#profilechild3icon1{
    font-size: 2rem;
    border: 5px solid black;
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
}

#profilechild3icon2{
    width: 70%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    color: var(--black);
}

#profilechild3icon2arrow{
    font-size: 1rem;
    border: 2px solid #0eaff1;
    width: 45px;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

}

@media screen and (max-width:900px) {
    #profilechild1bottomh1{
        font-size: 1.8rem;
    }

    #profilechild1bottomp{
        font-size: 1rem;
    }

    .profilechild3{
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
    }

    
}

@media screen and (max-width:650px)
{
    .ProfileMain{
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .profilechild1{
        width: 100%;
        height: 30vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .profilechild1h1{
        height: 100%;
        font-size: 1.5rem;
        color: var(--black);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .profilechild1bottom{
        height: 100%;
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    #profilechild1bottomh1{
        width: 100%;
    }

    #profilechild1bottomp{
        width: 100%;
    }

    .profilechild2{
        width: 100%;
        height: 350px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
    }

    .profilechild2bg{
        width: 350px;
        height: 100%;
        border-radius: 50%;
        background-color: #0eaff1;
    }

    .profilechild3{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: relative;
    }
}

@media screen and (max-width:520px)
{
    .profilechild1h1{
        font-size: 1.5rem;
        margin-left: 5px;
    }

    .profilechild1{
        height: 300px;
    }

    .profilechild2bg{
        width: 80%;
        height: 95%;
        border-radius: 50%;
        background-color: #0eaff1;
    }

    .profilechild1{
        align-items: flex-end;
        height: 180px;
    }

    .profilechild1h1{
        font-size: 1.5rem;
        margin-left: 20px;
    }

    .profilechild1bottom{
        justify-content: flex-end;
    }

    #profilechild3icons{
        position: absolute;
        /*transform: translatey(-150%);*/
        min-width: 60%;
        min-height: 100%;
        top: -130%;
        left: 20%;
    }

    .profilechild3{
        margin-top: 100px;
    }

    #profilechild3top{
        align-items: flex-start;
        padding-left: 10px;
    }

    #profilechild3bottom{
        padding: initial;
        padding-right: 10px;
    }
}