.review-container{
    width: 90vw;
    height: 400px;
    background-color: var(--bg);
}

.review-box{
    width: calc(100% / 3);
    max-height: 230px;
    align-self: center;
    background-color: #fff;
    border-radius: 10px;
}