.footer-container{
    border-top: 1px solid white;
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: row;
    margin-top: 15vh;
    padding-top: 10vh;
}

.footer-child{
    width: calc(100% / 5);
    height: 100%;
}

#amazon-logo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
}

#ft-logo{
    width: 50px;
    height: 50px;
}

#ft-content{
    font-size: 1.5rem;
    height: 15%;
    font-weight: 600;
    margin-left: 5px;
    color: var(--black);
    border-bottom: 2px solid var(--pink);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ft-child-heading{
    height: 20%;
    font-size: 1.5rem;
    color: var(--black);
    font-weight: 600;
    margin-top: 5%;
}

.ft-child-content{
    display: flex;
    flex-direction: row;
    height: 20%;
    align-items: center;
    margin-bottom: 5%;
    color: var(--black);
}

.ft-child-content i{
    font-size: 1.5rem;
    min-width: 30px;
    min-height: 30px;
    margin-right: 5px;
    color: var(--black);
}

@media screen and (max-width:910px) {
    .ft-child-heading{
        font-size: 1.2rem;
    }
    #mail{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size:0.8rem;
    }

    .ft-child-content p{
        font-size: 0.8;
    }

    #ft-logo{
        font-size: 1.5rem;
        width: 35px;
        height: 35px;
    }

    #amazon-logo{
        justify-content: flex-start;
    }
}

@media screen and (max-width:700px)
{
    #ft-logo{
        width: 30px;
        height: 30px;
    }

    #ft-content{
        font-size: 1rem;
    }

    .ft-child-content p{
        font-size: 0.8rem;
    }
}

@media screen and (max-width:500px)
{
    .footer-container{
        border-top: 1px solid white;
        width: 100%;
        height: initial;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        padding-top: 0;
    }

    .footer-child{
        width: 70%;
        height: initial;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        margin-left: 100px;
    }

    #amazon-logo{
        justify-content: flex-start;
        align-items: center;
        margin: initial;
        margin-bottom: 10px;
    }

    #ft-logo{
        width: 60px;
        height: 60px;
    }

    #ft-content{
        font-size: 2.5rem;
    }

    .ft-child-heading{
        font-size: 2rem;
        margin-bottom: 10px;
    }

}