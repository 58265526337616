.Navbar-container{
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.mainlogo{
    height: 100%;
    min-width: 20%;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mainlogo img{
    max-width: 50px;
    max-height: 50px;
    vertical-align: middle;
}

.nav-buttons{
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.nav-buttons a{
    margin-right: 5%;
    font-weight: 500;
    font-size: 1.15rem;
    color: var(--black);
    text-decoration: none;
}

.nav-buttons a:hover{
    cursor: pointer;
}

#inputnav{
    border: none;
    border-radius: 10px;
    padding: 2px;
    padding-left: 5px;
    min-height: 25px;
}

.carti{
    font-size: 1.5rem;
}

.bar-container{
    min-width: 35px;
    min-height: 30px;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    right: 1rem;
}

.bar{
    border: 2px solid var(--pink);
}

@media screen and (max-width:900px) {
    #inputnava{
        position: absolute;
        display: none;
    }
}

@media screen and (max-width:700px)
{
    #ENG{
        position: absolute;
        display: none;
    }

    .mainlogo{
        font-size: 1.1rem;
        margin-left: 5px;
    }

    .mainlogo img{
        max-width: 30px;
        max-height: 30px;
    }
}

@media screen and (max-width:650px)
{
    .Navbar-container{
        width: 100%;
        height: 15vh;
    }
}

@media screen and (max-width:500px) {
    .bar-container{
        display: flex;
    }

    .bar-container:hover{
        cursor: pointer;
    }

    .nav-buttons{
        display: none;
    }

    .nav-buttonsstyle{
        width: 100px;
        height: 180px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        background-color: #fff;
        padding-left: 10px;
        position: absolute;
        right: 3.5rem;
        top: 1.8rem;
    }

    .nav-buttons a{
        font-size: 0.9rem;
        font-weight: 600;
        text-decoration: none;
        color: black;
    }

    .mainlogo{
        margin-left: 10px;
        font-size: 2rem;
    }

    .mainlogo img{
        max-width: 50px;
        max-height: 50px;
        vertical-align: middle;
    }
}