.Product-container{
    margin: 5% 8%;
    height: 180px;
    border-radius: 20px;
    background-color: #fff;
    overflow: hidden;
}

.Product-heading{
    height: 20%;
    margin-top: 5%;
    font-size: 1.5rem;
    font-weight: 600;
    padding-left: 10px;
    text-align: center;
}

.Product-description{
    height: 70%;
    display: flex;
    flex-direction: row;
}

.Product-content{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 10%;
}

.Product-image{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.pd-img{
    max-width: 80px;
    min-height: 150px;
    position: relative;
    top: 10px;
    transform: rotate(-30deg);
}

.Product-p
{
    font-size: 0.7rem;
}

.Product-h1{
    font-size: 2.5rem;
}

.Product-button{
    width: 60%;
    height: 18%;
    border-radius: 15px;
    border: 1px solid var(--black);
    background-color: #fff;
    font-size: 0.7rem;
}

@media screen and (max-width:1050px) {  
    .Product-container{
        margin: 5% 7%;
    }
}

@media screen and (max-width:700px) {
    .Product-container{
        margin: 10% 20%;
    }
}

@media screen and (max-width:550px) {

    .Product-button{
        width: 90%;
        height: 18%;
        font-size: 0.7rem;
    }
}

@media screen and (max-width:400px) {
    .Product-button{
        width: 95%;
        height: 18%;
        font-size: 0.6rem;
    }
}

@media screen and (max-width:300px) {
    .Product-button{
        width: 100%;
        height: 18%;
        font-size: 0.6rem;
    }
}