.Testimonial-container{
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-top: 20vh;
}

.Testimonial-child1{
    height: 90%;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.Testimonial-child2{
    height: 90%;
    width: 40%;
    text-align: center;   
}

.Testimonial-child3{
    height: 90%;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.Testimonial-child2 img{
    max-width: 350px;
}

.Testimonial-childh1{
    color: var(--black);
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 5%;
}

.Testimonial-childp{
    color: var(--black);
    font-size: 0.9rem;
}

@media screen and (max-width:1000px)
{
    .Testimonial-container{
        justify-content: space-around;
    }

    .Testimonial-childp{
        font-size: 0.8rem;
    }

    .Testimonial-childh1{
        font-size: 1.5rem;
    }
}

@media screen and (max-width:600px) {
    .Testimonial-container{
        height: initial;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px auto;
    }
    .Testimonial-child1{
        height: 90%;
        min-width: 65%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items:center ;
    }
    
    .Testimonial-child2{
        height: 250px;
        min-width: 70%;
        text-align: center;   
    }
    
    .Testimonial-child3{
        height: 90%;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Testimonial-childh1{
        color: var(--black);
        font-size: 2rem;
        font-weight: 600;
        margin: initial;
    }
    
    .Testimonial-childp{
        color: var(--black);
        font-size: 1rem;
    }
}